<template>
  <div id="TALENT">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg04">
          <h3 class="title">
            인재상
          </h3>
          <p class="txt">
            SK텔링크가 바라는 인재상은 경영철학에 대한 확신을 바탕으로<br>
            일과 싸워서 이기는 패기를 실천하는 인재입니다.
          </p>
          <img
            src="/content/images/sub_header_cp04.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- company -->
        <div class="company mb0">
          <div class="cp_area">
            <div class="cp_box">
              <h4>
                경영철학에 대한 확신
              </h4>
              <p>
                VWBE를 통한 <span class="txt_point01">SUPEX추구 문화</span>로
                이해관계자 행복구현
              </p>
              <div class="supex_list">
                <div>
                  <img
                    src="/content/images/img_cp_vwbe.png"
                    alt="자발적이고 (Voluntarily) 의욕적으로 (Willingly) 두뇌활동 (Brain Engagement)"
                  >
                </div>
                <div class="supex_txt">
                  <h5>
                    SUPEX
                  </h5>
                  <p>
                    인간의 능력으로 도달할 수 있는<br>최고의 수준인
                    <span class="txt_point01">Super Excellent</span>수준
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="cp_area gray">
            <div class="cp_box">
              <h4>
                패기
              </h4>
              <p>
                스스로 동기를 부여하고 높은 목표에 도전하고 기존의<br class="m_br">
                틀을 깨는 과감한 실행을 한다.<br class="w_hide">
                그 과정에서 필요한<br class="m_br">
                역량을 개발하기 위해 노력하며 팀웍을 발휘한다
              </p>
              <div class="img_box">
                <img
                  src="/content/images/img_cp_guts.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'Talent',
  metaInfo: {
    title: '인재상 | 채용안내 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
