<template>
  <div id="HR_CULTURE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg04">
          <h3 class="title">
            HR제도안내
          </h3>
          <p class="txt">
            SK텔링크와 함께 할 우수한 인재들의<br>
            발전과 가능성을 항상 응원합니다
          </p>
          <img
            src="/content/images/sub_header_cp04.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- company -->
        <div class="company mb0">
          <!-- 2017-08-03 클래스추가 -->
          <div class="hr_area">
            <h4>채용안내</h4>
            <p>
              SK텔링크의 채용은
              <span
                class="txt_point01"
              >서류전형, SK종합역량검사,<br class="m_br">
                면접전형</span>을 통해 이루어지며,<br class="w_hide">
              회사 인재상에 부합하는<br class="m_br">
              인재를 선발하는데 최우선 목표를 두고 있습니다.
            </p>
            <ul class="step_list">
              <li>
                <h5>Step1</h5>
                <div>
                  <img
                    src="/content/images/img_hr_step01.png"
                    alt=""
                  >
                </div>
                <strong>서류전형</strong>
              </li>
              <li>
                <h5>Step2</h5>
                <div>
                  <img
                    src="/content/images/img_hr_step02.png"
                    alt=""
                  >
                </div>
                <strong>종합역량검사</strong>
              </li>
              <li>
                <h5>Step3</h5>
                <div>
                  <img
                    src="/content/images/img_hr_step03.png"
                    alt=""
                  >
                </div>
                <strong>면접전형</strong>
              </li>
            </ul>

            <div class="hr_cont">
              <!-- 웹 -->
              <div class="table_type04 w_hide">
                <table>
                  <caption>
                    구분, 신입, 경력 나타내는 표입니다.
                  </caption>
                  <colgroup>
                    <col style="width:18%;">
                    <col style="width:41%;">
                    <col style="width:41%;">
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        구분
                      </th>
                      <th scope="col">
                        신입
                      </th>
                      <th scope="col">
                        경력
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>채용시기</td>
                      <td>매년 하반기 1회 / 필요시</td>
                      <td>Business Needs 발생시</td>
                    </tr>
                    <tr>
                      <td>채용유형</td>
                      <td>
                        SK Group공채 (<a
                          href="http://www.skcareers.com"
                          target="_blank"
                          title="새창열림"
                          class="btn_link"
                        >www.skcareers.com</a>) / SK텔링크공채
                      </td>
                      <td>수시채용</td>
                    </tr>
                    <tr>
                      <td>서류전형</td>
                      <td>
                        SK그룹 공통 핵심가치와 보유 역량을 종합적으로
                        검토합니다.
                      </td>
                      <td>사전에 제시된 Job Requirement에 따라 시행됩니다</td>
                    </tr>
                    <tr>
                      <td>SK종합역량검사</td>
                      <td colspan="2">
                        SK에서 요구되는 조직생활 적응도와 직무 수행에 필요한
                        지적 기초 역량을 검증합니다.
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="2">
                        면접진행
                      </td>
                      <td>
                        <span class="txt_point03">1차 면접(실무면접)</span> :
                        직무관련 기본역량을 평가합니다
                      </td>
                      <td>
                        <span class="txt_point03">1차 면접(실무면접)</span> :
                        직무관련 전문역량을 평가합니다
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <span class="txt_point03">2차 면접(임원면접)</span> :
                        회사 인재상과 부합 여부 및 SK그룹 공통 핵심가치 수준을
                        종합적으로 평가합니다.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- //웹 -->

              <!-- 모바일 -->
              <div class="tab_area m_hide">
                <div class="tab_scroll03">
                  <div>
                    <ul class="inline-box">
                      <li
                        :class="tabIndex == 1 ? 'on': ''"
                      >
                        <div
                          @click="tabActive(1)"
                        >
                          신입
                        </div>
                      </li>
                      <li
                        :class="tabIndex == 2 ? 'on': ''"
                      >
                        <div
                          @click="tabActive(2)"
                        >
                          경력
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="tab_cont">
                  <!-- 신입 -->
                  <div
                    v-if="tabIndex == 1"
                    id="tab1"
                    class="tab_cont_inner on"
                  >
                    <h5 class="blind">
                      신입
                    </h5>
                    <div class="table_type01">
                      <dl>
                        <dt>채용시기</dt>
                        <dd>매년 하반기 1회 / 필요시</dd>
                      </dl>
                      <dl>
                        <dt>채용유형</dt>
                        <dd>
                          SK Group공채 (<a
                            href="http://www.skcareers.com"
                            target="_blank"
                            title="새창열림"
                            class="btn_link"
                          >www.skcareers.com</a>) / SK텔링크공채
                        </dd>
                      </dl>
                      <dl>
                        <dt>서류전형</dt>
                        <dd>
                          SK그룹 공통 핵심가치와 보유 역량을 종합적으로
                          검토합니다.
                        </dd>
                      </dl>
                      <dl>
                        <dt>SK종합역량검사</dt>
                        <dd>
                          SK에서 요구되는 조직생활 적응도와 직무 수행에 필요한
                          지적 기초 역량을 검증합니다.
                        </dd>
                      </dl>
                      <dl>
                        <dt>면접진행</dt>
                        <dd>
                          <ul class="hr_list">
                            <li>
                              <h5>&middot; 1차 면접(실무면접)</h5>
                              <p>- 직무관련 기본역량을 평가합니다</p>
                            </li>
                            <li>
                              <h5>&middot; 2차 면접(임원면접)</h5>
                              <p>
                                - 회사 인재상과 부합 여부 및 SK그룹 공통
                                핵심가치 수준을 종합적으로 평가합니다.
                              </p>
                            </li>
                          </ul>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <!-- //신입 -->

                  <!-- 경력 -->
                  <div
                    id="tab2"
                    :class="tabIndex == 2 ? 'on': ''"
                  >
                    <h5 class="blind">
                      경력
                    </h5>
                    <div class="table_type01">
                      <dl>
                        <dt>채용시기</dt>
                        <dd>Business Needs 발생시</dd>
                      </dl>
                      <dl>
                        <dt>채용유형</dt>
                        <dd>수시채용</dd>
                      </dl>
                      <dl>
                        <dt>서류전형</dt>
                        <dd>사전에 제시된 Job Requirement에 따라 시행됩니다</dd>
                      </dl>
                      <dl>
                        <dt>SK종합역량검사</dt>
                        <dd>
                          SK에서 요구되는 조직생활 적응도와 직무 수행에 필요한
                          지적 기초 역량을 검증합니다.
                        </dd>
                      </dl>
                      <dl>
                        <dt>면접진행</dt>
                        <dd>
                          <ul class="hr_list">
                            <li>
                              <h5>&middot; 1차 면접(실무면접)</h5>
                              <p>- 직무관련 전문역량을 평가합니다</p>
                            </li>
                            <li>
                              <h5>&middot; 2차 면접(임원면접)</h5>
                              <p>
                                - 회사 인재상과 부합 여부 및 SK그룹 공통
                                핵심가치 수준을 종합적으로 평가합니다.
                              </p>
                            </li>
                          </ul>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <!-- //경력 -->
                </div>
              </div>
              <!-- //모바일 -->
            </div>
          </div>
          <!-- 2020-09-11 내용추가 -->
          <div class="hr_area gray">
            <h4>조직문화/복리후생</h4>
            <p>
              SK텔링크는
              <span
                class="txt_point01"
              >모든 구성원이 창의적으로 자기주도 업무<br class="m_br">수행을
                할 수 있도록 ‘자율’과 ‘신뢰’를 기반으로<br class="m_br">하는
                수평적인 조직문화를 지향</span>합니다.<br>
              또한
              <span
                class="txt_point01"
              >구성원과 구성원 가족의 행복을 위한 다양한<br
                class="m_br"
              >복리후생 제도를 운영</span>하고 있습니다.
            </p>
            <div class="promote_list type1">
              <div>
                <h5>수평적으로 일하는 문화</h5>
                <ul>
                  <li>
                    모든 구성원은 직급/직책 없이 ‘님’으로 호칭하여<br>누구나주도적으로
                    업무를 수행
                  </li>
                  <li>
                    구성원간 경쟁을 야기할 수 있는 상대평가를<br>폐지하고,구성원
                    개개인의 성과개선/성장을<br>위한 절대평가 시행(매월 업무에
                    대한<br class="m_br">Self Review 및 리더 Feedback)
                  </li>
                  <li>
                    동료 평가 및 동료 포상을 통해 평가과정에서<br>다양한
                    의견을 반영하고,구성원 상호간 협력 문화 구축
                  </li>
                </ul>
              </div>
              <div>
                <h5>자율/창의적인 업무 환경</h5>
                <ul>
                  <li>
                    높낮이 조절 책상, 모니터 설치 좌석, 집중근무실 등<br>다양한
                    좌석이 조성된 사무실에서 매일<br class="m_br">
                    원하는 좌석을 선택하여 근무
                  </li>
                  <li>
                    최신 사양 PC 제공, Cloud PC 사용 등 개인별 최적의 업무환경을
                    제공하여<br>
                    팬데믹 환경 하에서 선제적 재택근무 시행
                  </li>
                  <li>
                    개인 Life Cycle 및 출퇴근 환경에 따라 <br
                      class="m_br"
                    >근무시간 자율 운영
                  </li>
                  <li>
                    매월 Happy Friday 시행<br class="m_br">(개인
                    시간 제공)
                  </li>
                  <li>
                    사내 Refresh 공간 조성<br class="m_br">
                    (휴게실, 수면실, Lounge, Café 등)
                  </li>
                </ul>
              </div>
              <div>
                <h5>구성원 자기개발/취미/여가활동 지원</h5>
                <ul>
                  <li>
                    선택적 복리후생비 지원<br
                      class="m_br"
                    >(자기개발, 국내외여행 등)
                  </li>
                  <li>사내 Fitness 센터 이용 전액 지원</li>
                  <li>사내 동호회 활동 지원 (문화, 독서, 스포츠 등)</li>
                  <li>전국 휴양시설 이용 지원 (회원가 이용 가능)</li>
                </ul>
              </div>
              <div>
                <h5>구성원 생활 지원</h5>
                <ul>
                  <li>최신 기종 업무용 휴대전화 및 통신비 지원</li>
                  <li>장기근속 휴가 및 포상금 제공 (10년, 15년, 20년)</li>
                  <li>구성원의 주거 및 생활안정을 위한 대부금 지원</li>
                  <li>SK Family Card 발급 및 연회비 지원</li>
                </ul>
              </div>
              <div>
                <h5>구성원 건강 지원</h5>
                <ul>
                  <li>매년 1회 전문 검진기관을 통한 건강검진 지원</li>
                  <li>매년 1회 독감예방 접종 지원</li>
                  <li>
                    본인과 가족 (부모, 배우자, 자녀) 연간 의료비 일부 지원
                  </li>
                  <li>단체 실비/간호비 특약 보험 가입</li>
                  <li>전문센터를 통한 심리상담 프로그램 제공</li>
                </ul>
              </div>
              <div>
                <h5>구성원 가족 지원</h5>
                <ul>
                  <li>본인 및 직계가족 경조금 및 화환/물품 지원</li>
                  <li>사내 어린이집 운영 (만 0세~5세 자녀)</li>
                  <li>유치원부터 대학교까지 자녀 학자금 지원</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- //2020-09-11 내용추가 -->
          <!-- 2020-09-11 내용변경 -->
          <div class="hr_area">
            <h4>육성</h4>
            <p>
              SK텔링크는 구성원 개인별 역량향상을 위해 SK 그룹의<br
                class="m_br"
              >
              공통 교육체계에 기반한 개인별 자기주도적 육성 및<br
                class="m_br"
              >
              SK그룹 Learning Portal을 지원하고 있습니다.<br>
              이를 통하여
              <span
                class="txt_point01"
              >회사는 비전 및 성과 달성을 위한 전략적<br class="m_br">
                인적자원을 확보하고 개인은 전문성과 경쟁력을 갖춘<br
                  class="m_br"
                >
                인재로 거듭나게 됩니다.</span>
            </p>
            <div class="promote_list">
              <div>
                <h5>SK그룹 공통 교육체계 공유</h5>
                <ul>
                  <li>· 리더양성 Course (임원/팀장 교육)</li>
                  <li>· 전문가양성 Course (전략/마케팅/재무/HR 등)</li>
                  <li>· 신입/영입 구성원 Course (SK그룹/SKMS 교육 등)</li>
                </ul>
              </div>
              <div>
                <h5>자기주도적 육성</h5>
                <ul>
                  <li>· 개인별 교육예산 부여</li>
                  <li>· 부족 역량 자가 진단</li>
                  <li>· 필요 교육 선정/자율적 참가 (리더 Coaching/조언)</li>
                </ul>
              </div>
              <div>
                <h5>SK그룹 Learning Portal</h5>
                <ul>
                  <li>· 자기주도 학습 플랫폼 “mySUNI”</li>
                  <li>· SK ICT 역량 개발 플랫폼 “Digital Learning Portal”</li>
                  <li>· 그룹 공통 학습 Tool “Mobile Academy”</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 2020-09-11 내용변경-->
          <div class="hr_area gray">
            <!-- //2020-09-11 내용변경  -->
          </div>
          <!-- //customer -->
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'HrCulture',
  metaInfo: {
    title: 'HR제도안내 | 채용안내 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1023px) {
  .tab_scroll03 ul li {
    &.on {
      div {
        color: #ff630a;
        border-bottom: 3px solid #ff630a;
      }
    }
    div {
      display: block;
      padding-bottom: 12px;
      color: #666;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 3px solid #fff;
    }
  }
}
</style>
