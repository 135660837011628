<template>
  <div id="INTRO_00700">
    <Header />
    <div
      id="container"
      class="sub_main"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="intro00700">
          <div class="sub_visual">
            <div class="inner">
              <h3>
                세계로 통하는 국가대표 국제전화<br>
                <img
                  src="/content/images/into_visual_bi.png"
                  alt="SK국제전화 00700"
                >
              </h3>
              <p>
                가족 및 지인과의 안부전화, 업무용 연락까지!<br>
                끊김없이 들리는 국제전화 00700 서비스를 제공하고 있습니다.
              </p>
              <a
                href="https://www.00700.com"
                class="btn_arrow"
                target="_blank"
                title="SK국제전화 00700 새창열림"
              >
                <span>SK국제전화 00700 바로가기</span>
              </a>
            </div>
          </div>

          <div class="intro_prologue">
            <h4>
              SK국제전화 00700은<br class="m_br"> 해외직통회선을 통해<br class="w_br">
              고품격<br class="m_br"> 통화품질과 차별화된 혜택을<br class="m_br"> 제공하는<br class="w_br">
              <span class="point_color">국내 1위의 국제전화<br class="m_br"> 대표 브랜드입니다.</span>
            </h4>
            <p>
              세계 최대 통신 사업자들과 제휴를 맺어 고품질의 통신 환경을 구축,<br>
              고객님의 목소리를 끊김없이 생생하게 전달하고 있습니다.<br>
              고객님의 국제전화 이용 패턴에 맞는 요금제 추천은 물론<br>
              다채로운 이벤트부터 상품권 등 추가 혜택까지!<br>
              국제전화에 대한 만족의 기준, SK국제전화 00700이 높이겠습니다.
            </p>
          </div>

          <div class="intro_free">
            <h4>
              <span class="point_color">6대 고객만족지수 1위!</span><br>
              <img
                src="/content/images/into_free_bi.png"
                alt="SK국제전화 00700"
              ><br>
              요금제 가입비 100% 무료!
            </h4>

            <dl class="item_list">
              <div class="item1">
                <dt>국제전화 00700</dt>
                <dd>
                  최고의 통화 품질과 남다른<br>
                  혜택으로 고객만족도 1위
                </dd>
              </div>
              <div class="item2">
                <dt>국제는 00700</dt>
                <dd>
                  별도의 가입없이<br>
                  국제SMS도 간편하게!
                </dd>
              </div>
            </dl>
          </div>

          <div class="intro_recommend">
            <p>
              국제전화 이용 패턴에 꼭 맞는<br class="m_br"> 국제전화 요금제를 추천해드려요!<br>
              요금제 가입하시면 <strong>평균 60%까지<br class="m_br"> 할인된<br class="w_br"> 요금을</strong> 만나보실 수 있습니다.
            </p>
            <a
              href="https://www.00700.com/charge"
              class="btn_arrow"
              target="_blank"
              title="SK국제전화 00700 새창열림"
            >
              <span>SK국제전화 요금제 바로가기</span>
            </a>

            <div class="scroll">
              <div class="scroll_inner">
                <dl class="item_list">
                  <div class="item1">
                    <dt>요금 할인</dt>
                    <dd>
                      국내통화 요금으로 국제전화까지
                    </dd>
                  </div>
                  <div class="item2">
                    <dt>무료 통화</dt>
                    <dd>
                      쓰면 쓸수록 국제전화를 더!
                    </dd>
                  </div>
                  <div class="item3">
                    <dt>상품권 및 제휴 혜택</dt>
                    <dd>
                      국제전화 사용금액만큼 상품권으로 페이백
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div class="intro_use">
            <h4>00700 사용 방법</h4>
            <ol class="item_list">
              <li class="item1">
                00700 누르고
              </li>
              <li class="item2">
                국가번호 누르고(미국:1)
              </li>
              <li class="item3">
                상대방번호 누르면 끝!<br>(전화번호에서 앞자리 0은 제외)
              </li>
            </ol>

            <a
              href="https://www.00700.com/information/how-to-use"
              class="btn_arrow"
              target="_blank"
              title="SK국제전화 00700 새창열림"
            >
              <span>더욱 편리한 국제전화 만나러 가기</span>
            </a>
          </div>

          <div class="intro_fiveFree">
            <div class="inner">
              <h4>
                국제전화 5분 무료체험<br>
                <strong>신청만 하면 누구나 가능!</strong>
              </h4>
              <p>
                국제전화 00700 그래서 얼마나 좋은데?<br>
                궁금한 고객님들을 위해 국제전화 00700<br class="m_br"> 5분 무료 체험을 선물해 드립니다.
              </p>
              <p>
                국제전화 5분 통화 시 00700 통화금액=0원
              </p>
              <a
                href="https://www.00700.com/free"
                class="btn_arrow"
                target="_blank"
                title="SK국제전화 00700 새창열림"
              >
                <span>바로 신청하러 가기</span>
              </a>
              
              <dl class="customer_center">
                <dt>SK국제전화 00700 고객센터</dt>
                <dd>1599-0070(유료)/080-890-0700(무료)</dd>
                <dd>운영시간:평일 오전 9시~오후 6시(주말 및 공휴일 휴무)</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
// import Input from '@/components/Input'


export default {
  name: "Intro",
  metaInfo: {
    title: '국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
  },
  emits: [],
  data() {
    return {
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
$point_color: red;
$text_color222: #222;
$text_color333: #333;
$text_color444: #444;
$text_color666: #666;
.point_color { color: $point_color; font-weight: inherit; line-height: inherit; }
.intro00700 {
  letter-spacing: -0.075em;
  .sub_visual {
    height: 373px;
    margin-top: 58px;
    background: url('/content/images/intro_visual.jpg') no-repeat center 0;
    .inner {
      width: 1120px;
      margin: 0 auto;
      padding-top: 41px;
      text-align: center;
      h3 {
        color: $text_color222;
        font-size: 50px;
        font-weight: 400;
      }
      img { width: auto; margin-top: 21px; }
      P {
        margin-top: 25px;
        color: $text_color222;
        font-size: 20px;
        line-height: 25px;
      }
        .btn_arrow {
          height: 51px;
          margin-top: 13px;
          padding: 0 58px;
          color: #fff;
          font-size: 24px;
          line-height: 51px;
          background: #e51937;
          & > span { padding-right: 0; background: none; }
        }
    }
  }
  .intro_prologue {
    width: 1120px;
    margin: 0 auto;
    padding: 76px 0;
    text-align: center;
    h4 {
      color: $text_color333;
      font-size: 44px;
      line-height: 60px;
    }
    p {
      margin-top: 25px;
      color: $text_color333;
      font-size: 30px;
      line-height: 40px;
    }
  }
  .intro_free {
    width: 1120px;
    margin: 0 auto;
    padding: 53px 0 0;
    text-align: center;
    border-top: 1px dotted #ddd;
    h4 {
      color: $text_color333;
      font-size: 44px;
      font-weight: 300;
      line-height: 60px;
      span { font-weight: 400 }
    }
    img { width: auto; margin: 14px 0 11px; }
    .item_list {
      display: flex;
      height: 309px;
      margin-top: 40px;
      & > div {
        width: 100%;
        padding: 90px 0 0 294px;
        text-align: left;
        letter-spacing: -0.05em;
        background: none no-repeat 62px 17px;
        &:not(:first-child) {
          border-left: 1px dotted #ddd;
        }
        dt {
          color: $text_color444;
          font-size: 30px;
          font-weight: 400;
          line-height: 40px;
        }
        dd {
          margin-top: 27px;
          color: $text_color666;
          font-size: 20px;
          font-weight: 300;
          line-height: 28px;
        }
      }
      .item1 { background-image: url('/content/images/into_free_img1.png'); }
      .item2 { background-image: url('/content/images/into_free_img2.png'); }
    }
  }
  .intro_recommend {
    text-align: center;
    padding: 60px 0 52px;
    background-color: #f2f2f2;
    border-top: 1px dotted #ddd;
    p {
      color: $text_color333;
      font-size: 44px;
      font-weight: 300;
      line-height: 60px;
      strong { font-weight: 400; }
    }
    .btn_arrow {
      height: 61px;
        margin-top: 33px;
        padding: 0 58px;
        color: #fff;
        font-size: 24px;
        line-height: 61px;
        background: #e51937;
      
        &>span {
          padding-right: 0;
          background: none;
        }
    }
    .item_list {
      display: flex;
      width: 1120px;
      margin: 48px auto 0;
      height: 332px;
      & > div {
        width: 100%;
        padding-top: 245px;
        letter-spacing: -0.05em;
        background: none no-repeat center 29px;
        &:not(:first-child) {
          border-left: 1px dotted #ddd;
        }
        dt {
          color: $text_color444;
          font-size: 24px;
          font-weight: 400;
          line-height: 40px;
        }
        dd {
          margin-top: 10px;
          color: $text_color666;
          font-size: 20px;
          line-height: 28px;
        }
      }
      .item1 { background-image: url('/content/images/into_recommend_img1.png'); }
      .item2 { background-image: url('/content/images/into_recommend_img2.png'); }
      .item3 { background-image: url('/content/images/into_recommend_img3.png'); }
    }
  }
  .intro_use {
    padding: 62px 0 70px;
    text-align: center;
    border-top: 1px dotted #ddd;
    h4 {
      display: inline-block;
      color: $text_color333;
      font-size: 44px;
      font-weight: 300;
      line-height: 62px;
      border-bottom: 2px solid #f87e57;
    }
    .item_list {
      display: flex;
      width: 1120px;
      margin: 80px auto 0;
      li {
        position: relative;
        width: 100%;
        padding-top: 295px;
        color: $text_color666;
        font-size: 24px;
        line-height: 30px;
        background: none no-repeat center 0;
        &:not(:first-child):before {
          content: '';
          position: absolute;
          left: -26px; top: 120px;
          width: 52px; height: 36px;
          background: url('/content/images/into_use_arrow.png') no-repeat;
        }
      }
      .item1 { background-image: url('/content/images/into_use_img1.png'); }
      .item2 { background-image: url('/content/images/into_use_img2.png'); }
      .item3 { background-image: url('/content/images/into_use_img3.png'); }
    }
    .btn_arrow {
      height: 61px;
      margin-top: 33px;
      padding: 0 58px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background: #e51937;
      & > span { padding-right: 0; background: none; }
    }
  }
  .intro_fiveFree {
    height: 525px;
    background: url('/content/images/intro_fiveFree.jpg') no-repeat center 0;
    .inner {
      width: 1120px;
      margin: 0 auto;
      padding-top: 65px;
      text-align: center;
      h4 {
        color: #fff;
        font-size: 44px;
        line-height: 54px;
        strong {
          display: block;
          margin-top: 19px;
          color: #0effff;
          font-size: 30px;
          line-height: 33px;
          font-weight: 300;
        }
      }
      h4 + p {
        margin-top: 22px;
      }
      p {
        margin-top: 30px;
        color: #fff;
        font-size: 30px;
        line-height: 33px;
        &:first-child { margin-top: 28px; }
      }
      .btn_arrow {
        margin-top: 33px;
        padding: 0 25px 0 28px;
        color: #111;
        background: #8b87ef;
        & > span { padding-right: 34px; }
      }
      .customer_center {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 28px;
        color: #fff;
        dt { width: 100%; font-size: 17px; }
        dd {
          position: relative;
          margin-left: 12px;
          padding-left: 12px;
          font-size: 17px;
          &:not(:nth-child(2)):before {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            width: 1px; height: 15px;
            background-color: #fff;
          }
        }
      }
    }
  }
}
@media (max-width: 1023px) {
.intro00700 {
  .btn_arrow { letter-spacing: -0.075em; }
  .sub_visual {
    height: 258px;
    margin-top: 0;
    background-image: url('/content/images/intro_visual_m.jpg');
    background-size: cover;
    .inner {
      width: 100%;
      padding-top: 34px;
      h3 {
        font-size: 17px;
      }
      img { width: 150px; margin-top: 9px; }
      P {
        margin-top: 15px;
        font-size: 13px;
        line-height: 15px;
      }
      .btn_arrow {
        margin-top: 30px;
        span { background-size: 22px auto; }
      }
    }
  }
  .intro_prologue {
    width: 100%;
    padding: 39px 0;
    h4 {
      font-size: 25px;
      line-height: 30px;
    }
    p {
      margin-top: 14px;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .intro_free {
    width: 100%;
    padding: 37px 0 0;
    h4 {
      font-size: 25px;
      line-height: 27px;
    }
    img { width: 129px; margin: 15px 0 12px; }
    .item_list {
      display: block;
      height: auto;
      margin-top: 0;
      & > div {
        padding: 95px 0 70px 52%;
        background-position: 63px 49px;
        background-size: 110px auto;
        &:not(:first-child) {
          border-left: none;
          border-top: 1px dotted #ddd;
        }
        dt {
          font-size: 20px;
          line-height: 20px;
        }
        dd {
          margin-top: 12px;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
  .intro_recommend {
    padding: 40px 0 0;
    p {
      font-size: 25px;
      line-height: 32px;
    }
    .btn_arrow {
      height: 37px;
      margin-top: 33px;
      font-size: 15px;
      line-height: 37px;
    }
    .scroll {
      .scroll_inner {
        overflow-x: auto;
        padding: 45px 40px 50px;
      }
    }
    .item_list {
      width: 730px;
      height: auto;
      margin: 0;
      & > div {
        padding-top: 166px;
        background-position: center 0;
        background-size: auto 150px;
        &:not(:first-child) {
          border-left: none;
        }
        dt {
          font-size: 20px;
          line-height: 20px;
        }
        dd {
          margin-top: 19px;
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
  }
  .intro_use {
    padding: 38px 0 30px;
    text-align: center;
    border-top: 1px dotted #ddd;
    h4 {
      font-size: 25px;
      line-height: 35px;
    }
    .item_list {
      display: flex;
      flex-flow: wrap;
      width: 100%;
      margin: 45px 0 0;
      li {
        padding-top: 150px;
        font-size: 15px;
        line-height: 17px;
        background-size: auto 134px;
        &:not(:first-child) {
          margin-top: 65px;
        }
        &:not(:first-child):before {
          position: absolute;
          left: calc(50% - 13px); top: -40px;
          width: 26px; height: 18px;
          transform: rotate(90deg);
          background-size: 100%;
        }
      }
    }
    .btn_arrow {
      display: block;
      height: 52px;
      margin: 15px 15px 0;
      padding: 0;
      font-size: 18px;
      line-height: 52px;
      & > span { padding-right: 0; background: none; }
    }
  }
  .intro_fiveFree {
    height: 376px;
    background-image: url('/content/images/intro_fiveFree_m.jpg');
    background-size: cover;
    .inner {
      width: 100%;
      padding-top: 32px;
      h4 {
        color: #fff;
        font-size: 25px;
        line-height: 27px;
        strong {
          margin-top: 16px;
          font-size: 15px;
          line-height: 20px;
        }
      }
      h4 + p {
        margin-top: 14px;
      }
      p {
        margin-top: 14px;
        font-size: 15px;
        line-height: 20px;
        &:first-child { margin-top: 0; }
      }
      .btn_arrow {
        margin-top: 25px;
        padding: 0 16px 0 20px;
        & > span { padding-right: 34px; }
      }
      .customer_center {
        margin-top: 29px;
        line-height: 17px;
        dt { font-size: 13px; line-height: 17px; }
        dd {
          margin-left: 0;
          padding-left: 0;
          font-size: 13px;
          line-height: 17px;
          &:not(:nth-child(2)):before {
            display: none;
          }
        }
      }
    }
  }
}
}
</style>
