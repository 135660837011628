<template>
  <div
    id="ENG_MILESTONE"
    class="eng"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents en"
      >
        <div class="sub_header bg01 en">
          <h3 class="title">
            Milestone
          </h3>
          <p class="txt">
            SK telink, which has grown with customers, is <br>realizing another future with customers.
          </p>
          <img
            src="/content/images/sub_header_cp01.jpg"
            alt="Visual image"
          >
        </div>

        <!-- tab -->
        <!-- to. 개발 / tab 기능 필요 -->
        <div class="tab_type01 tab4">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li
              id="taba"
              :class="tabIndex == 1 ? 'on': ''"
              title="현재메뉴 선택됨"
            >
              <div
                @click="tabActive(1)"
              >
                2021~Currently
              </div>
            </li>
            <li
              id="tabb"
              :class="tabIndex == 2 ? 'on': ''"
            >
              <div
                @click="tabActive(2)"
              >
                2011~2020
              </div>
            </li>
            <li
              id="tabc"
              :class="tabIndex == 3 ? 'on': ''"
            >
              <div
                @click="tabActive(3)"
              >
                2001~2010
              </div>
            </li>
            <li
              id="tabd"
              :class="tabIndex == 4 ? 'on': ''"
            >
              <div
                @click="tabActive(4)"
              >
                1998~2000
              </div>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <!-- 상단 tab에서 클릭시 해당 .history_area 블록에 display:block -->
          <div
            v-if="tabIndex == 1"
            id="tab1"
            class="history_area"
          >
            <div class="history_list">
              <div class="txt_box type">
                <h4>2021</h4>
                <ul>
                  <li><strong class="txt_point01">10.</strong> Won the Korean Customer Satisfaction Index (KCSI) Awards (for 7 consecutive years)</li>
                  <li><strong class="txt_point01">09.</strong> Won the National Brand Competitiveness Index (NBCI) Awards (for 13 consecutive years)</li>
                  <li><strong class="txt_point01">07.</strong> Won the Korean Standard Premium Brand Index (KS-PBI) Awards (in MVNO category)</li>
                  <li><strong class="txt_point01">07.</strong> Won the Korean Standard Service Quality Index (KS-SQI) (for 13 consecutive years)</li>
                  <li><strong class="txt_point01">03.</strong> Won the National Customer Satisfaction Index (NCSI) Awards (for 8 consecutive years)</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-if="tabIndex == 2"
            id="tab2"
            class="history_area"
          >
            <div class="history_list">
              <div class="txt_box type">
                <h4>2020</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">12.</strong> Won the Korean Net Promoter Score (KNPS) Awards (for 5 consecutive
                    years)
                  </li>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the Korean Customer Satisfaction Index (KCSI) Awards (for 6
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">09.</strong> Won the Korean Standard Premium Brand Index (KS-PBI) Awards (for 9
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the National Brand Competitiveness Index (NBCI) Awards (for 12
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the Korean Standard Service Quality Index (KS-SQI) (for 12
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the National Customer Satisfaction Index (NCSI) Awards (for 7
                    consecutive years)
                  </li>
                </ul>
              </div>
            </div>
            <div class="history_list">
              <div class="txt_box type">
                <h4>2019</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">12.</strong> Won the Korean Net Promoter Score (KNPS) Awards (for 4 consecutive
                    years)
                  </li>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the Korean Customer Satisfaction Index (KCSI) Awards (for 5
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">09.</strong> Won the Korean Standard Premium Brand Index (KS-PBI) Awards (for 8
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the National Brand Competitiveness Index (NBCI) Awards (for 11
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the Korean Standard Service Quality Index (KS-SQI) (for 11
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the National Customer Satisfaction Index (NCSI) Awards (for 6
                    consecutive years)
                  </li>
                </ul>
              </div>
            </div>
            <div class="history_list">
              <div class="txt_box type">
                <h4>2018</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">12.</strong> Won the Korean Net Promoter Score (KNPS) Awards (for 3 consecutive
                    years)
                  </li>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the Korean Customer Satisfaction Index (KCSI) Awards (for 4
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the National Brand Competitiveness Index (NBCI) Awards (for 10
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the Korean Standard Premium Brand Index (KS-PBI) Awards (for 7
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the Korean Standard Service Quality Index (KS-SQI) (for 10
                    consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the National Customer Satisfaction Index (NCSI) Awards (for 5
                    consecutive years)
                  </li>
                </ul>
              </div>
            </div>
            <div class="history_list">
              <div class="txt_box">
                <h4>2017</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">12.</strong> Won the first prize in the international call service category at
                    the Korean Net Promoter Score (KNPS) Awards (for 2 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the first prize in the international call service category at
                    the Korean Customer Satisfaction Index (KCSI) Awards (for 3 consecutive years)
                  </li>
                  <li><strong class="txt_point01">09.</strong> Renovated our MVNO brand to ‘SK 7 mobile’</li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 9 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the first prize in the international call service category at
                    the Korean Standard Premium Brand Index (KS-PBI) Awards (for 6 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index (KS-SQI) (for 9 consecutive years)
                  </li>
                  <li><strong class="txt_point01">06.</strong> Launched the MVNO Gongbueuishin</li>
                  <li>
                    <strong class="txt_point01">05.</strong> Commercialized the world’s first VoLTE international call service
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the first prize in the international call service category at
                    the National Customer Satisfaction Index (NCSI) Awards (for 4 consecutive years)
                  </li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2017.png"
                  alt="공부의神 image"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2016</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">12.</strong> Received the highest MVNO grade in Korea Communications Commission
                    user protection evaluation (for 3 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">12.</strong> Won the first prize in the international call service category at
                    the Korean Net Promoter Score (KNPS) Awards
                  </li>
                  <li><strong class="txt_point01">10.</strong> Acquired a security company NSOK</li>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the first prize in the international call service category at
                    the Korean Customer Satisfaction Index (KCSI) Awards (for 2 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 8 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the first prize in the international call service category at
                    the Korean Standard Premium Brand Index(KS-PBI) Awards (for 5 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index(KS-SQI) Awards (for 8 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">04.</strong> Received the Web Accessibility Quality Certification Mark from the
                    Ministry of Science, ICT and Future Planning
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the first prize in the international call service category at
                    the National Customer Satisfaction Index (NCSI) Awards (for 3 consecutive years)
                  </li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2016.png"
                  alt="SK telink + NSOK image"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2015</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the first prize in the international call service category at
                    the Korean Customer Satisfaction Index (KCSI) Awards
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 7 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Succeeded in the VoLTE international call test for the first time
                    in the world
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the first prize in the international call service category at
                    the Korean Standard Premium Brand Index (KS-PBI) Awards (for 4 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">06.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index (KS-SQI) Awards (for 7 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">04.</strong> Received the Web Accessibility Quality Certification Mark from the
                    Ministry of Science, ICT and Future Planning
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the first prize in the international call service category at
                    the National Customer Satisfaction Index (NCSI) Awards (for 2 consecutive years)
                  </li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2015.png"
                  alt="VoLTE image"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2014</h4>
                <ul>
                  <li><strong class="txt_point01">10.</strong> Started the mobile fax service</li>
                  <li>
                    <strong class="txt_point01">09.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 6 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">07.</strong> Won the first prize in the international call service category at
                    the Korean Standard Premium Brand Index (KS-PBI) Awards
                  </li>
                  <li>
                    <strong class="txt_point01">06.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index (KS-SQI) Awards (for 6 consecutive years)
                  </li>
                  <li><strong class="txt_point01">06.</strong> Launched the satellite communication service “SK Smartsat”</li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the first prize in the international call service category at
                    the National Customer Satisfaction Index (NCSI) Awards
                  </li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2014.png"
                  alt="Mobile FAX image"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2013</h4>
                <ul>
                  <li><strong class="txt_point01">11.</strong> Started the 1800 universal area number service</li>
                  <li><strong class="txt_point01">10.</strong> MVNO subscribers exceeded 300,000.</li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 5 consecutive years)
                  </li>
                  <li><strong class="txt_point01">07.</strong> Started the brand call service</li>
                  <li>
                    <strong class="txt_point01">06.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index (KS-SQI) Awards (for 5 consecutive years)
                  </li>
                  <li><strong class="txt_point01">01.</strong> Started the post-paid MVNO service</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2013.png"
                  alt="1800 브랜드콜 image"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2012</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index (KS-SQI) Awards (for 4 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 4 consecutive years)
                  </li>
                  <li><strong class="txt_point01">08.</strong> Ended the satellite DMB service</li>
                  <li><strong class="txt_point01">06.</strong> Started the prepaid MVNO service</li>
                  <li><strong class="txt_point01">05.</strong> Started the video conference service (VaaS)</li>
                  <li>
                    <strong class="txt_point01">03.</strong> Launched the international direct dialing ‘00700’ app for
                    smartphones
                  </li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2012.png"
                  alt="SK 국제전화 00700 image"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2011</h4>
                <ul>
                  <li>
                    <strong class="txt_point01">10.</strong> Won the first prize in the international call service category at
                    the Korean Standard Service Quality Index (KS-SQI) Awards (for 3 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">08.</strong> Won the first prize in the international call service category at
                    the National Brand Competitiveness Index (NBCI) Awards (for 3 consecutive years)
                  </li>
                  <li>
                    <strong class="txt_point01">03.</strong> Won the first prize in the international call service category at
                    the National Customer Satisfaction Index(NCSI) Awards (for 6 consecutive years)
                  </li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2011.png"
                  alt="NBCI, KS-SQI, NCSI image"
                >
              </div>
            </div>
          </div>

          <div
            v-if="tabIndex == 3"
            id="tab3"
            class="history_area img_no"
          >
            <div class="history_list">
              <div class="txt_box">
                <h4>2010</h4>
                <ul>
                  <li><strong class="txt_point01">11.</strong> Merged with TU Media Corp</li>
                  <li><strong class="txt_point01">10.</strong> Won the first prize in the international call service category at the Korean Standard Service Quality Index (KS-SQI) Awards (for 2 consecutive years)</li>
                  <li><strong class="txt_point01">08.</strong> Won the first prize in the international call service category at the National Brand Competitiveness Index (NBCI) Awards (for 2 consecutive years)</li>
                  <li><strong class="txt_point01">04.</strong> Won the first prize in the international call service category at the National Customer Satisfaction Index (NCSI) Awards (for 5 consecutive years)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2010.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2009</h4>
                <ul>
                  <li><strong class="txt_point01">10.</strong> Won the first prize in the international call service category at the Korean Standard Service Quality Index (KS-SQI) Awards</li>
                  <li><strong class="txt_point01">09.</strong> Won the first prize in the international call service category at the Korean Customer Satisfaction Index( KCSI) Awards</li>
                  <li><strong class="txt_point01">09.</strong> Won the first prize in the international call service category at the National Brand Competitiveness Index (NBCI) Awards</li>
                  <li><strong class="txt_point01">04.</strong>  Won the first prize in the international call service category at the National Customer Satisfaction Index(NCSI) Awards (for 4 consecutive years)</li>
                  <li><strong class="txt_point01">02.</strong> Started the Office Lettering service for the first time in Korea</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2009.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2008</h4>
                <ul>
                  <li><strong class="txt_point01">05.</strong> Started the Lettering service for corporations for the first time in Korea</li>
                  <li><strong class="txt_point01">03.</strong> Won the first prize in the international call service category at the National Customer Satisfaction Index (NC냐) Awards (for 3 consecutive years)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2008.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2007</h4>
                <ul>
                  <li><strong class="txt_point01">09.</strong> Started the FMC (Fixed Mobile Convergence) service jointly with SK Telecom</li>
                  <li><strong class="txt_point01">07.</strong> Started the video telephony interworking between WCDMA and landline phones for the first time in Korea</li>
                  <li><strong class="txt_point01">03.</strong> Won the first prize in the international call service category at the National Customer Satisfaction Index (NCSI) Awards (for 2 consecutive years)</li>
                  <li><strong class="txt_point01">02.</strong> Provided the world’s first mobile T-commerce service (CJ Home Shopping channel)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2007.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2006</h4>
                <ul>
                  <li><strong class="txt_point01">10.</strong> Won the first prize in the international call service category at the National Brand Competitiveness Index (NBCI) Awards</li>
                  <li><strong class="txt_point01">08.</strong> Started the Nate On Phone (web portal soft phone) service</li>
                  <li><strong class="txt_point01">04.</strong> Won the first prize in the international call service category at the National Customer Satisfaction Index (NCSI) Awards</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2006.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2005</h4>
                <ul>
                  <li><strong class="txt_point01">12.</strong> Started the 1599 universal area number service</li>
                  <li><strong class="txt_point01">12.</strong> Started the Internet phone service</li>
                  <li><strong class="txt_point01">11.</strong> Won the first prize in the international call service category at the National Brand Competitiveness Index (NBCI) Awards</li>
                  <li><strong class="txt_point01">06.</strong> Obtained the Internet phone/city value-added telecommunication business right</li>
                  <li><strong class="txt_point01">02.</strong> Started the SK long-distance call service</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2005.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2004</h4>
                <ul>
                  <li><strong class="txt_point01">06.</strong> Obtained the long-distance call common carrier license</li>
                  <li><strong class="txt_point01">04.</strong> Started the international SMS service (for the first time in Korea)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2004.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2003</h4>
                <ul>
                  <li><strong class="txt_point01">08.</strong> Started the SK Bizlink VPN service</li>
                  <li><strong class="txt_point01">06.</strong> Started the SK Bizlink SMS service</li>
                  <li><strong class="txt_point01">06.</strong> Obtained the international call common carrier license (identification number 006)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2003.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2002</h4>
                <ul>
                  <li><strong class="txt_point01">11.</strong> Provided SK leased line service</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2002.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2001</h4>
                <ul>
                  <li><strong class="txt_point01">05.</strong> Started the SK Bizlink service</li>
                  <li><strong class="txt_point01">04.</strong> Started the VoIP Co-Location service</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2001.png"
                  alt=""
                >
              </div>
            </div>
          </div>

          <div
            v-if="tabIndex == 4"
            id="tab4"
            class="history_area img_no"
          >
            <div class="history_list">
              <div class="txt_box">
                <h4>2000</h4>
                <ul>
                  <li><strong class="txt_point01">06.</strong> Started the SK Express service in Busan</li>
                  <li><strong class="txt_point01">03.</strong> Started the Inmarsat service</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2000.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>1999</h4>
                <ul>
                  <li><strong class="txt_point01">12.</strong> Opened the new Express Tandem Switch (ETS)</li>
                  <li><strong class="txt_point01">10.</strong> Relocated the International Gateway Office (IGW)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history1999.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>1998</h4>
                <ul>
                  <li><strong class="txt_point01">11.</strong> Started selling the SK international call prepaid card</li>
                  <li><strong class="txt_point01">10.</strong> Started the SK Express service</li>
                  <li><strong class="txt_point01">09.</strong> Started the ‘SK international call’ commercial service</li>
                  <li><strong class="txt_point01">09.</strong> Obtained the international call identification number (00700)</li>
                  <li><strong class="txt_point01">07.</strong> Founded SK telink Co., Ltd.</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history1998.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: 'Milestone',
  metaInfo: {
    title: 'Milestone | Company | SK telink',
    titleTemplate: null
  },
  components: {
    Header,
    BreadCrumb,
    Footer
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
