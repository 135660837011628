<template>
  <div id="INTRODUCE_MILESTONE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg01">
          <h3 class="title">
            연혁
          </h3>
          <p class="txt">
            고객과 함께 성장해온 SK텔링크는<br> 고객과 함께 또 다른 미래를 실현합니다
          </p>
          <img
            src="/content/images/sub_header_cp01.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- tab -->
        <!-- to. 개발 / tab 기능 필요 -->
        <div class="tab_type01 tab4">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li
              id="taba"
              :class="tabIndex == 1 ? 'on': ''"
              title="현재메뉴 선택됨"
            >
              <div @click="tabActive(1)">
                2021~현재
              </div>
            </li>
            <li
              id="tabb"
              :class="tabIndex == 2 ? 'on': ''"
            >
              <div @click="tabActive(2)">
                2011~2020
              </div>
            </li>
            <li
              id="tabc"
              :class="tabIndex == 3 ? 'on': ''"
            >
              <div @click="tabActive(3)">
                2001~2010
              </div>
            </li>
            <li
              id="tabd"
              :class="tabIndex == 4 ? 'on': ''"
            >
              <div @click="tabActive(4)">
                1998~2000
              </div>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <!-- 상단 tab에서 클릭시 해당 .history_area 블록에 display:block -->
          <div
            v-if="tabIndex == 1"
            id="tab1"
            class="history_area"
          >
            <div class="history_list">
              <div class="txt_box type">
                <h4>2022</h4>
                <ul>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 알뜰폰 부문 1위(2년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위(14년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> 소비자가 가장 추천하는 브랜드(KBRI) 알뜰폰 부문 1위(2년 연속)</li>
                  <li><strong class="txt_point01">04월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위(9년 연속)</li>
                </ul>
              </div>
            </div>
            <div class="history_list">
              <div class="txt_box type">
                <h4>2021</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위(7년 연속)</li>
                  <li><strong class="txt_point01">09월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위(13년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 알뜰폰 부문 1위</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위(13년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> 소비자가 가장 추천하는 브랜드(KBRI) 알뜰폰 부문 1위</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(8년 연속)</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-if="tabIndex == 2"
            id="tab2"
            class="history_area"
          >
            <div class="history_list">
              <div class="txt_box type">
                <h4>2020</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위(5년 연속)</li>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위(6년 연속)</li>
                  <li><strong class="txt_point01">09월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위(9년 연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위(12년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위(12년 연속)</li>
                  <li><strong class="txt_point01">05월</strong> SK네트웍스에 중고폰 사업부문 매각</li>
                  <li><strong class="txt_point01">05월</strong> GX Air 위성통신 면허(국경간 공급협정 승인) 획득</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(7년 연속)</li>
                </ul>
              </div>
            </div>
            <div class="history_list">
              <div class="txt_box type">
                <h4>2019</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상(4년 연속)</li>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(5년 연속)</li>
                  <li><strong class="txt_point01">09월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(8년 연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(11년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(11년 연속)</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(6년 연속)</li>
                </ul>
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box type">
                <h4>2018</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상(3년 연속)</li>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(4년 연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(10년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(7년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(10년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> SK 7mobile 효도의신 상품 출시</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(5년연속)</li>
                </ul>
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2017</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상(2년 연속)</li>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(3년 연속)</li>
                  <li><strong class="txt_point01">09월</strong> 알뜰폰 서비스 브랜드 'SK 7mobile'로 리뉴얼</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(9년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(6년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(9년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> 알뜰폰 공부의신 상품 출시</li>
                  <li><strong class="txt_point01">05월</strong> 세계 최초 VoLTE 국제전화 서비스 상용화</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(4년연속)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2017.png"
                  alt="공부의神 이미지"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2016</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 방송통신위원회 이용자보호업무평가 알뜰폰 최고등급(3년 연속)</li>
                  <li><strong class="txt_point01">12월</strong> 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상</li>
                  <li><strong class="txt_point01">10월</strong> 보안전문회사 엔에스오케이(NSOK) 인수</li>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(3년 연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(8년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(5년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(8년 연속)</li>
                  <li><strong class="txt_point01">04월</strong> 미래부 지정 국가 공인 웹접근성(Web Accessibility) 품질 인증마크 획득</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(3년연속)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2016.png"
                  alt="SK telink + NSOK 이미지"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2015</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(2년 연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(7년 연속)</li>
                  <li><strong class="txt_point01">08월</strong> 세계 최초 VoLTE 국제전화 테스트 성공</li>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(4년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(7년 연속)</li>
                  <li><strong class="txt_point01">04월</strong> 미래부 지정 국가 공인 웹접근성(Web Accessibility) 품질 인증마크 획득</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(2년연속)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2015.png"
                  alt="VoLTE 이미지"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2014</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 모바일팩스 서비스 개시</li>
                  <li><strong class="txt_point01">09월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(6년 연속)</li>
                  <li><strong class="txt_point01">07월</strong> 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(3년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(6년 연속)</li>
                  <li><strong class="txt_point01">06월</strong> 위성통신 서비스 “SK스마트샛” 출시</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2014.png"
                  alt="Mobile FAX 이미지"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2013</h4>
                <ul>
                  <li><strong class="txt_point01">11월</strong> 1800 전국대표번호 서비스 개시</li>
                  <li><strong class="txt_point01">10월</strong> 알뜰폰(MVNO) 30만 가입자 돌파</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수 (NBCI) 국제전화서비스 부문 1위 수상(5년연속)</li>
                  <li><strong class="txt_point01">07월</strong> 브랜드콜 서비스 개시</li>
                  <li><strong class="txt_point01">06월</strong> 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상(5년연속)</li>
                  <li><strong class="txt_point01">01월</strong> 알뜰폰(MVNO) 후불 사업 개시</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2013.png"
                  alt="1800 브랜드콜 이미지"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2012</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(4년연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(4년연속)</li>
                  <li><strong class="txt_point01">08월</strong> 위성DMB 서비스 종료</li>
                  <li><strong class="txt_point01">06월</strong> 알뜰폰(MVNO) 선불 사업 개시</li>
                  <li><strong class="txt_point01">05월</strong> 화상회의서비스(VaaS) 개시</li>
                  <li><strong class="txt_point01">03월</strong> 스마트폰 전용 국제전화 ‘00700’ 앱 출시</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2012.png"
                  alt="SK 국제전화 00700 이미지"
                >
              </div>
            </div>
          
            <div class="history_list">
              <div class="txt_box">
                <h4>2011</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상(3년연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(3년연속)</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(6년연속)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2011.png"
                  alt="NBCI, KS-SQI, NCSI 이미지"
                >
              </div>
            </div>
          </div>
          
          <div
            v-if="tabIndex == 3"
            id="tab3"
            class="history_area img_no"
          >
            <!-- 연혁에 이미지 수급 안되었을때 추가 -->
            <div class="history_list">
              <div class="txt_box">
                <h4>2010</h4>
                <ul>
                  <li><strong class="txt_point01">11월</strong> TU Media Corp와 합병</li>
                  <li><strong class="txt_point01">10월</strong> 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상(2년연속)</li>
                  <li><strong class="txt_point01">08월</strong> 국가브랜드경쟁력지수 (NBCI) 국제전화서비스 부문 1위 수상(2년연속)</li>
                  <li><strong class="txt_point01">04월</strong> 국가고객만족도 (NCSI) 국제전화서비스 부문 1위 수상(5년연속)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2010.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2009</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상</li>
                  <li><strong class="txt_point01">09월</strong> 한국산업의 고객만족도( KCSI) 국제전화서비스 부문 1위 수상</li>
                  <li><strong class="txt_point01">09월</strong> 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상</li>
                  <li><strong class="txt_point01">04월</strong> 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(4년연속)</li>
                  <li><strong class="txt_point01">02월</strong> 국내 최초 오피스 레터링 (Office Lettering) 서비스 개시</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2009.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2008</h4>
                <ul>
                  <li><strong class="txt_point01">05월</strong> 국내 최초 기업용 레터링(Lettering) 서비스 개시</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(3년연속)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2008.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2007</h4>
                <ul>
                  <li><strong class="txt_point01">09월</strong> SK텔레콤과 함께 FMC(Fixed Mobile Converngence) 서비스 개시</li>
                  <li><strong class="txt_point01">07월</strong> 국내최초 WCDMA-유선전화 간 영상전화 연동 개시</li>
                  <li><strong class="txt_point01">03월</strong> 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(2년연속)</li>
                  <li><strong class="txt_point01">02월</strong> 세계 최초 모바일 T-커머스 서비스 제공 (CJ 홈쇼핑 채널)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2007.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2006</h4>
                <ul>
                  <li><strong class="txt_point01">10월</strong> 국가 브랜드경쟁력지수(NBCI) 국제전화서비스부문 1위 수상</li>
                  <li><strong class="txt_point01">08월</strong> 네이트온폰(웹 포탈 소프트 폰) 서비스 개시</li>
                  <li><strong class="txt_point01">04월</strong> 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2006.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2005</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 1599전국대표번호 서비스 개시</li>
                  <li><strong class="txt_point01">12월</strong> 인터넷전화 서비스 개시</li>
                  <li><strong class="txt_point01">11월</strong> 국가 브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상</li>
                  <li><strong class="txt_point01">06월</strong> 인터넷전화/시내부가 기간 사업 권 획득</li>
                  <li><strong class="txt_point01">02월</strong> SK시외전화 서비스 개시</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2005.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2004</h4>
                <ul>
                  <li><strong class="txt_point01">06월</strong> 시외전화 기간사업자 면허 취득</li>
                  <li><strong class="txt_point01">04월</strong> 국제 SMS 서비스 개시 (국내최초)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2004.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2003</h4>
                <ul>
                  <li><strong class="txt_point01">08월</strong> SK Bizlink VPN 서비스 개시</li>
                  <li><strong class="txt_point01">06월</strong> SK Bizlink SMS 서비스 개시</li>
                  <li><strong class="txt_point01">06월</strong> 국제전화 기간사업자 면허 취득 (식별번호 006)</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2003.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2002</h4>
                <ul>
                  <li><strong class="txt_point01">11월</strong> SK 전용회선 서비스 제공</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2002.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>2001</h4>
                <ul>
                  <li><strong class="txt_point01">05월</strong> SK Bizlink 서비스 개시</li>
                  <li><strong class="txt_point01">04월</strong> VoIP Co-Location 서비스 개시</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2001.png"
                  alt=""
                >
              </div>
            </div>
          </div>

          <div
            v-if="tabIndex == 4"
            id="tab4"
            class="history_area img_no"
          >
            <!-- 연혁에 이미지 수급 안되었을때 추가 -->
            <div class="history_list">
              <div class="txt_box">
                <h4>2000</h4>
                <ul>
                  <li><strong class="txt_point01">06월</strong> 부산지역 SK Express 서비스 개시</li>
                  <li><strong class="txt_point01">03월</strong> 인마셋 서비스 개시</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history2000.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>1999</h4>
                <ul>
                  <li><strong class="txt_point01">12월</strong> 신규 법인접속교환기(ETS) 개통</li>
                  <li><strong class="txt_point01">10월</strong> 국제 관문국 (IGW) 이설</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history1999.png"
                  alt=""
                >
              </div>
            </div>

            <div class="history_list">
              <div class="txt_box">
                <h4>1998</h4>
                <ul>
                  <li><strong class="txt_point01">11월</strong> SK 국제전화 선불카드 판매 개시</li>
                  <li><strong class="txt_point01">10월</strong> SK Express 서비스 개시</li>
                  <li><strong class="txt_point01">09월</strong> ‘SK 국제전화’ 상용 서비스 개시</li>
                  <li><strong class="txt_point01">09월</strong> 국제전화 식별번호 (00700) 취득</li>
                  <li><strong class="txt_point01">07월</strong> SK텔링크주식회사 설립</li>
                </ul>
              </div>
              <div class="img_box">
                <img
                  src="/content/images/img_history1998.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>

        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'Milestone',
  metaInfo: {
    title: '연혁 | SK텔링크 소개 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
