<template>
  <div id="MAIN_PAGE">
    <Header />
    <div id="container">
      <div class="key_slide">
        <Swiper />
        <!--20210727 swiper 컴포넌트 생성 -->
      </div>

      <ul class="service_banners">
        <li class="inter">
          <dl>
            <dt>
              <router-link to="/view/business/00700intro">
                국제전화 00700
              </router-link>
            </dt>
            <dd>
              <router-link to="/view/business/00700intro">
                고객이 인정한 최고의 서비스와 품질로<br />
                전세계를 끊김없이 통화할 수 있는<br />국가대표 국제전화 00700
              </router-link>
            </dd>
          </dl>
        </li>
        <li class="comp">
          <dl>
            <!-- to개발 / 2021-02-24 퍼블수정 | 텍스트 수정 -->
            <dt>
              <router-link to="/view/business/inmarsat">
                위성통신서비스
              </router-link>
            </dt>
            <dd>
              <router-link to="/view/business/inmarsat">
                지상통신이 되지 않는 곳에서도<br />
                안정적이고 끊김없는<br />글로벌 위성통신서비스를 만나보세요
              </router-link>
            </dd>
            <!-- //to개발 / 2021-02-24 퍼블수정 | 텍스트 수정 -->
          </dl>
        </li>
        <li class="mobile_cell">
          <dl>
            <dt>
              <router-link to="/view/business/sk7mobile">
                SK 7mobile
              </router-link>
            </dt>
            <dd>
              <router-link to="/view/business/sk7mobile">
                SK의 품질과 신뢰로<br />프리미엄 모바일 경험을 제공하는<br />나를
                아끼는 모바일 SK 7mobile<!-- 20211006 - 문구 수정 -->
              </router-link>
            </dd>
          </dl>
        </li>
      </ul>

      <div class="money">
        <h4>
          마음편하게 국제전화하는 방법!<br />
          <strong>
            <em>
              <span>
                <img
                  src="/content/images/index/fee_00700.png"
                  alt="SK 국제전화 00700"
                />
              </span>
              추천요금제와 특별한 혜택을 확인해보세요
            </em>
          </strong>
        </h4>
        <Carousel
          ref="carouselProduct"
          :items="productItems"
          :options="productOptions"
        />
      </div>

      <div class="notice">
        <Notice />
      </div>
    </div>
    <Footer />
    <!-- 사업 양수도 공지 팝업 -->
    <PopupNotice
      :is-visible.sync="popup.notice.isVisible"
      @change="changeNoticePopup"
    />
    <!-- 결산공고 팝업 -->
    <PopupAnnounce :is-visible.sync="popup.announce.isVisible" />
    <SummitControl />
  </div>
</template>

<script>
import api from "@/api/index.js";
import util from "@/utilities";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Swiper from "../components/Swiper"; //20210727
import Notice from "../components/Notice";
import SummitControl from "../components/SummitControl";
import PopupNotice from "@/components/popup/PopupNotice";
import PopupAnnounce from "@/components/popup/PopupAnnounce";

export default {
  name: "Main",
  metaInfo: {
    title: "메인",
  },
  components: {
    Header,
    Footer,
    Carousel,
    Notice,
    PopupNotice,
    PopupAnnounce,
    SummitControl,
    Swiper, //20210727
  },
  props: {},
  emits: [],
  data() {
    return {
      productItems: [],
      firstClone: [],
      lastClone: [],
      productOptions: {
        slideWidth: 1120,
        slideToShow: 4,
      },
      popup: {
        notice: {
          isVisible: false,
        },
        announce: {
          // 결산공고 팝업
          isVisible: false,
        },
      },
      startDate: new Date("2024-03-28"), // 결산공고 팝업 노출 시작 날짜
      endDate: new Date("2024-04-03"), // 결산공고 팝업 노출 종료 날짜
    };
  },
  computed: {
    totalWidth() {
      return (
        this.productOptions.slideWidth *
        (this.productItems.length / this.productOptions.slideToShow)
      );
    },
  },
  watch: {},
  created() {
    if (util.getCookie("notice") !== "Y") {
      this.popup.notice.isVisible = false;
    }
    if (util.getCookie("announce") !== "Y") {
      // 결산공고 팝업
      this.popup.announce.isVisible = false;

      // 결산공고 팝업 특정 기간 노출
      this.checkPopupStatus();
    }

    api.MainProduct("/data/main/product.json").then((response) => {
      this.productItems = response.data;
      const pcMatch = window.matchMedia("screen and (min-width: 769px)");
      if (pcMatch.matches) {
        if (this.productItems) {
          this.init();
        }
      }
    });
  },
  methods: {
    init() {
      this.firstClone = this.productItems.slice(
        0,
        this.productOptions.slideToShow
      );
      this.lastClone = this.productItems.slice(
        -this.productOptions.slideToShow
      );
      this.productItems.unshift(...this.lastClone);
      this.productItems.push(...this.firstClone);
      this.$refs.carouselProduct.$refs.carouselList.style.transition = "0ms";
      this.$refs.carouselProduct.$refs.carouselList.style.transform = `translate3d(-${this.productOptions.slideWidth}px, 0, 0)`;
      this.$refs.carouselProduct.$refs.carouselList.style.width = `${this.totalWidth}px`;
    },
    changeNoticePopup(value) {
      if (value) {
        util.setCookie("notice", "Y", 1);
        this.popup.notice.isVisible = false;
      }
    },
    checkPopupStatus() {
      const currentDate = new Date();

      if (currentDate >= this.startDate && currentDate <= this.endDate) {
        this.popup.announce.isVisible = true;
      } else {
        this.popup.announce.isVisible = false;
      }
    },
  },
};
</script>

<style lang="scss">
.money {
  .swiper-wrapper {
    .carousel {
      &__list {
        li {
          &:nth-child(4n-2) {
            margin-right: 140px;
          }
          .carousel__image {
            width: 245px !important;
          }
        }
      }
    }
    @media (max-width: 1023px) {
      .carousel {
        overflow: visible !important;
        &__list {
          display: inline-flex !important;
          li {
            &:nth-child(4n-2) {
              margin-right: 0;
            }
            .carousel__image {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
</style>
