<template>
  <div
    id="BUSINESS"
    class="eng"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents en"
      >
        <div class="sub_header bg02">
          <h3 class="title">
            Business
          </h3>
          <p class="txt">
            Bringing customers closer to the world,<br>SK telink brings customers closer to the future.
          </p>
          <img
            src="/content/images/sub_header_cp02.jpg"
            alt="Visual image"
          >
        </div>

        <!-- company -->
        <div class="company">
          <div
            class="busines_area"
            style="display:none;"
          >
            <!-- 0826숨김처리 -->
            <div>
              <h4>SK telink, puts top priority on the<br><span class="txt_point01">happiness of customers.</span></h4>
              <p>
                SK telink,
                <span class="txt_point01">No. 1 on the National Customer Satisfaction Index (NCSI),<br>No. 1 on the National Brand Competitiveness Index (NBCI),<br>and No. 1 on the Korean Standard Service Quality Index (KS-SQI)</span>, puts top priority on the happiness of customers.
              </p>
              <div class="img_box three">
                <span><img
                  src="/content/images/img_bus_nbci.png"
                  alt="No. 1 on the National Brand Competitiveness Index (NBCI)"
                ></span>
                <span><img
                  src="/content/images/img_bus_ncsi.png"
                  alt="No. 1 on the National Customer Satisfaction Index (NCSI)"
                ></span>
                <span><img
                  src="/content/images/img_bus_kssqi.png"
                  alt="No. 1 on the Korean Standard Service Quality Index (KS-SQI)"
                ></span>
              </div>
            </div>
          </div>

          <div class="busines_area alone">
            <!-- 0826한개만노출일때 -->
            <div>
              <h4>
                Wired, wireless and satellite communication!
                <br>
                Seamless telecommunication service provider, SK telink
              </h4>
              <h5>SK telink’s efforts to create a communication world in which <span>customers are happy</span></h5>

              <p>
                <!-- to개발 / 2021-02-25 퍼블수정 | span.txt_point01 추가, br 추가 -->
                SK telink has started an international direct dialing service and, with a long-term and professional experience,  <br>
                continuously diversified its business to MVNO and satellite communication services, <br>
                and <span class="txt_point01">has become a global borderless telco to provide its excellent quality, <br>diversified products and advanced services.</span>
                <!-- //to개발 / 2021-02-25 퍼블수정 | span.txt_point01 추가, br 추가 -->
              </p>

              <div class="busines_slide">
                <div class="swiper-wrapper">
                  <!-- to.개발 / 모바일일때 .slick-slide 1개가 가로로 꽉차는 슬라이드 아이템이어야함. -->
                  <div class="slick-slide">
                    <div class="img_box">
                      <img
                        src="/content/images/img_bus_slide01.png"
                        alt=""
                      >
                    </div>
                    <div class="txt_box">
                      <strong>International Direct Dialing</strong>
                      <ul>
                        <li>&middot; You can conveniently use international direct dialing ‘00700’ to more than 200 countries by using mobile or fixed phone.</li>
                        <li>&middot; We offer overseas direct lines in 30 countries, and other high-quality lines to provide clear and excellent call quality </li>
                        <li>&middot; We provide differentiated services that meet customers’ needs with optimized plan offerings and target marketing</li>
                      </ul>
                    </div>
                  </div>
                  <div class="slick-slide">
                    <div class="img_box">
                      <img
                        src="/content/images/img_bus_slide02.png"
                        alt=""
                      >
                    </div>
                    <div class="txt_box">
                      <strong>Satellite Communication Service</strong>
                      <ul>
                        <li>&middot; We provide stable global satellite communication services where there is no ground communication such as ships and remote areas.</li>
                        <li>&middot; We offer Inmarsat FB service, the most common satellite communication service through the 4th generation satellite </li>
                        <li>&middot; We strengthen customer competitiveness by providing various additional solutions combined with satellite communication service</li>
                      </ul>
                    </div>
                  </div>
                  <div class="slick-slide">
                    <div class="img_box">
                      <img
                        src="/content/images/img_bus_slide03.png"
                        alt=""
                      >
                    </div>
                    <div class="txt_box">
                      <strong>SK 7mobile</strong>
                      <ul>
                        <li>&middot; We are using the mobile communication network of SK Telecom to provide mobile communication service based on reasonable rates and excellent call quality.</li>
                        <li>&middot; We provide the call quality of SK Telecom, the reliable No. 1 common carrier in Korea.</li>
                        <li>&middot; We received the highest MVNO grade in Korea Communications Commission user protection evaluation, and we are taking the lead in protecting the rights of customers.</li>
                        <li>&middot; According to customers’ lifestyle and communication service usage patterns, we are providing reasonable plans and services.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: 'Business',
  metaInfo: {
    title: 'Business | SK telink',
    titleTemplate: null
  },
  components: {
    Header,
    BreadCrumb,
    Footer
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
